import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import Div100vh from "react-div-100vh";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Main = styled.main`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: ${props => props.desktopBgColor};

  @media (max-width: 767px) {
    background-color: ${props => props.mobileBgColor};
  }
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          desktop_background_color
          mobile_background_color
        }
      }
    }
  `);

  return (
    <>
      <Normalize />
      <GlobalStyles />
      <DefaultSEO />
      <Div100vh>
        <Main
          desktopBgColor={data.prismicHomepage.data.desktop_background_color}
          mobileBgColor={data.prismicHomepage.data.mobile_background_color}
        >
          <App children={children} />
        </Main>
      </Div100vh>
    </>
  );
};

export default Layout;
